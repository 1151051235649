<template>
  <div>
    <div v-if="(!formSubmitSuccess && fromApplicant) || !fromApplicant">
      <FormKit
        v-if="!selectedCompany && !fromApplicant"
        v-model="selectedCompany"
        type="select"
        select-icon="down"
        :options="
          availableCompanies.map((c) => ({
            value: c,
            label: c.attributes.title,
          }))
        "
      />
      <FormKit
        v-else-if="
          ($route.params.id && status == 'success') || !$route.params.id
        "
        v-slot="{ value: formValues }"
        type="form"
        autocomplete="off"
        :submit-label="
          $t(
            application
              ? `form.application.edit.submit.label`
              : fromApplicant
                ? `form.application.create.submit.label`
                : `form.application.save.submit.label`,
          )
        "
        :disabled="status === 'pending'"
        @submit="crudSubmit"
      >
        <FormKit
          v-if="region && region.id && fromApplicant"
          :value="region.id"
          type="hidden"
          name="regionSource"
          number
        />
        <FormKit
          v-if="application && application.id"
          type="hidden"
          name="id"
          number
          :value="application.id"
        />
        <FormKit
          :value="
            fromApplicant ? 'open' : (application?.attributes.status as any)
          "
          :type="fromApplicant ? 'hidden' : 'select'"
          name="status"
          select-icon="down"
          :label="$t(`form.applicationStatus.label`)"
          :placeholder="$t(`form.applicationStatus.placeholder`)"
          validation="required"
          :validation-messages="{
            required: $t(`form.applicationStatus.validation.required`),
          }"
          validation-visibility="blur"
          :options="
            [
              { value: 'open', label: $t(`form.applicationStatus.open`) },
              {
                value: 'interesting',
                label: $t(`form.applicationStatus.interesting`),
              },
              {
                value: 'contacted',
                label: $t(`form.applicationStatus.contacted`),
              },
              { value: 'invited', label: $t(`form.applicationStatus.invited`) },
              { value: 'hired', label: $t(`form.applicationStatus.hired`) },
              {
                value: 'rejected',
                label: $t(`form.applicationStatus.rejected`),
              },
              { value: 'deleted', label: $t(`form.applicationStatus.deleted`) },
            ] as any[]
          "
        />
        <FormKit
          v-if="fromApplicant && vacancyId"
          type="hidden"
          name="vacancy"
          number
          :value="vacancyId"
        />
        <FkStrapiCollection
          v-else-if="selectedCompany && selectedCompany.id && !fromApplicant"
          :collection="'accountVacancy'"
          :use-pagination="false"
          :use-filterbar="false"
          :filters="{ company: { id: selectedCompany.id } }"
          :pagination="{
            page: 1,
            pageSize: 9999,
          }"
          sort="expiresAt:desc"
        >
          <template #items="{ data }">
            <FormKit
              type="select"
              select-icon="down"
              name="vacancy"
              :value="
                application?.attributes.vacancy.data
                  ? application?.attributes.vacancy.data.id
                  : null
              "
              :label="$t(`form.applicationVacancy.label`)"
              :placeholder="$t(`form.applicationVacancy.placeholder`)"
              :options="
                [
                  { value: null, label: $t('general.openApplication') } as any,
                ].concat(
                  data.data.map((vac) => ({
                    value: vac.id,
                    label: vac.attributes.title,
                  })),
                )
              "
            />
          </template>
        </FkStrapiCollection>
        <FormKit
          :value="application?.attributes.firstName"
          type="text"
          name="firstName"
          :label="$t(`form.firstName.label`)"
          :placeholder="$t(`form.firstName.placeholder`)"
          validation="required"
          :validation-messages="{
            required: $t(`form.firstName.validation.required`),
          }"
          validation-visibility="blur"
        />
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-4">
          <FormKit
            :value="application?.attributes.infix"
            type="text"
            name="infix"
            :label="$t(`form.infix.label`)"
            :placeholder="$t(`form.infix.placeholder`)"
            outer-class="optional-label max-w-32 sm:max-w-none"
          />
          <FormKit
            :value="application?.attributes.lastName"
            type="text"
            name="lastName"
            :label="$t(`form.lastName.label`)"
            :placeholder="$t(`form.lastName.placeholder`)"
            validation="required"
            :validation-messages="{
              required: $t(`form.lastName.validation.required`),
            }"
            validation-visibility="blur"
            outer-class="sm:col-span-2"
          />
        </div>
        <FormKit
          :value="application?.attributes.birthDate"
          type="date"
          name="birthDate"
          :label="$t(`form.birthDate.label`)"
          :placeholder="$t(`form.birthDate.placeholder`)"
          validation="required"
          :validation-messages="{
            required: $t(`form.birthDate.validation.required`),
          }"
          validation-visibility="blur"
        />
        <FormKit
          :value="application?.attributes.email"
          type="email"
          name="email"
          :label="$t(`form.email.label`)"
          :placeholder="$t(`form.email.placeholder`)"
          outer-class="optional-label"
          validation="required|email"
          :validation-messages="{
            required: $t(`form.email.validation.required`),
            email: $t(`form.email.validation.email`),
          }"
          validation-visibility="blur"
        />
        <FormKit
          :value="application?.attributes.phone"
          type="text"
          name="phone"
          :label="$t(`form.phone.label`)"
          :placeholder="$t(`form.phone.placeholder`)"
          validation="required"
          :validation-messages="{
            required: $t(`form.phone.validation.required`),
          }"
          validation-visibility="blur"
        />

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-4">
          <FormKit
            :value="application?.attributes.street"
            type="text"
            name="street"
            :label="$t(`form.street.label`)"
            :placeholder="$t(`form.street.placeholder`)"
            outer-class="optional-label col-span-2"
            validation="required"
          />
          <FormKit
            :value="application?.attributes.houseNumber"
            type="text"
            name="houseNumber"
            :label="$t(`form.houseNumber.label`)"
            :placeholder="$t(`form.houseNumber.placeholder`)"
            outer-class="optional-label max-w-24 sm:max-w-none"
            validation="required"
          />
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-x-4">
          <FormKit
            :value="application?.attributes.postalCode"
            type="text"
            name="postalCode"
            :label="$t(`form.postalCode.label`)"
            :placeholder="$t(`form.postalCode.placeholder`)"
            outer-class="optional-label max-w-32 sm:max-w-none"
          />
          <FormKit
            :value="application?.attributes.place"
            type="text"
            name="place"
            :label="$t(`form.place.label`)"
            :placeholder="$t(`form.place.placeholder`)"
            outer-class="optional-label  col-span-2"
          />
        </div>
        <FormKit
          :value="application?.attributes.gender"
          type="radio"
          name="gender"
          :label="$t(`form.gender.label`)"
          :placeholder="$t(`form.gender.placeholder`)"
          outer-class="optional-label"
          decorator-icon="circle"
          :options="{
            male: $t('form.gender.options.male'),
            female: $t('form.gender.options.female'),
            other: $t('form.gender.options.other'),
          }"
        />
        <template v-if="application">
          <div v-if="application.attributes.cv" class="my-base-1/2">
            <button
              class="btn"
              @click.prevent="downloadFile(application.attributes.cv)"
            >
              {{ $t("general.downloadCv") }}
            </button>
          </div>
          <div v-if="application.attributes.motivation" class="my-base-1/2">
            <button
              class="btn"
              @click.prevent="downloadFile(application.attributes.motivation)"
            >
              {{ $t("general.downloadMotivation") }}
            </button>
          </div>
        </template>
        <template v-else>
          <FormKit
            type="file"
            :multiple="false"
            :label="$t(`form.cv.label`)"
            name="cv"
            accept=".doc,.docx,.pdf"
          />
          <FormKit
            v-if="
              !(formValues && formValues.motivationTextualBool) ||
              !fromApplicant
            "
            type="file"
            :multiple="false"
            :label="$t(`form.motivationUpload.label`)"
            name="motivation"
            accept=".doc,.docx,.pdf"
          />
        </template>
        <div>
          <FormKit
            v-if="fromApplicant"
            name="motivationTextualBool"
            type="checkbox"
            :label="$t(`form.motivationTextualBool.label`)"
            decorator-icon="check"
          />

          <FormKit
            v-if="
              (formValues && formValues.motivationTextualBool) || !fromApplicant
            "
            :value="application?.attributes.motivationTextual"
            type="textarea"
            name="motivationTextual"
            :label="$t(`form.motivationTextual.label`)"
            :placeholder="$t(`form.motivationTextual.placeholder`)"
            outer-class="optional-label"
          />

          <FormKit
            v-if="fromApplicant"
            :value="application?.attributes.jobAlerts ?? true"
            type="checkbox"
            outer-class="py-8"
            name="jobAlerts"
            :label="$t(`form.jobAlerts.label`)"
            decorator-icon="check"
          />

          <FormKit
            v-if="fromApplicant && formValues && formValues.jobAlerts"
            :value="regions?.map((e: any) => e.id)"
            name="regions"
            type="checkbox"
            decorator-icon="check"
            :label="$t(`form.jobAlertRegions.label`)"
            :options="
              regions?.map((e: any) => ({
                value: e.id,
                label: e.attributes.municipality,
              }))
            "
          />

          <FormKit
            v-if="fromApplicant"
            :value="application?.attributes.baanTreffers ?? true"
            type="checkbox"
            outer-class="py-8"
            name="baanTreffers"
            :label="$t(`form.baanTreffers.label`)"
            decorator-icon="check"
          />
        </div>
      </FormKit>
      <div
        v-if="formSubmitSuccess && !fromApplicant"
        class="fixed right-[1rem] bottom-[1rem] max-w-[24rem] bg-green-800 text-light text-lg px-4 pt-5 pb-4 rounded"
      >
        <h3 class="font-normal">{{ $t("form.application.submitSuccess") }}</h3>
      </div>
    </div>
    <div v-else-if="fromApplicant" class="fk-prose py-base text-center">
      <h2>
        {{ $t(`form.thankYouForApplication.title`) }}
      </h2>
      <p>{{ $t(`form.thankYouForApplication.text`) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import { useReCaptcha } from "vue-recaptcha-v3";

const props = defineProps<{
  vacancyId?: number | undefined;
  fromApplicant?: boolean;
}>();

const { selectedCompany, availableCompanies } = useWerkgelukUser();
const { region, regions } = useStaticData();
// const localePath = useLocalePath();
const { saveApplication, fetchApplication } = useApplication();
const route = useRoute();
const { t } = useI18n();
const client = useStrapiClient();

const {
  data: application,
  refresh,
  status,
} = useAsyncData(async () => {
  if (route.params.id) {
    return await fetchApplication(parseInt(`${route.params.id}`));
  }
  return null;
});

onUnmounted(() => {
  application.value = null;
});

const formSubmitSuccess = ref(false);
const recaptchaInstance = useReCaptcha();
const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded();
  return await recaptchaInstance?.executeRecaptcha("login");
};

//Set the formSubmitSuccess notification to false after 3200 miliseconds
watch(formSubmitSuccess, (value) => {
  if (value) {
    setTimeout(() => {
      if (!props.fromApplicant) {
        formSubmitSuccess.value = false;
        // navigateTo({ path: "/mijn-account/sollicitaties-overzicht" });
      }
    }, 3200);
  }
});

const downloadFile = (fileName: string) => {
  const client = useStrapiClient();
  /* eslint-disable @typescript-eslint/no-explicit-any */
  client(`/attachment/${fileName}`, {
    method: "GET",
    responseType: "blob",
  }).then((response: any) => {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Use the fileName string as the filename
    document.body.appendChild(a); // Required for Firefox
    a.click();
    document.body.removeChild(a); // Cleanup
    window.URL.revokeObjectURL(url); // Cleanup
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const crudSubmit = async (data: any, node: FormKitNode | undefined) => {
  node?.clearErrors();

  data.recaptcha = await recaptcha();

  await saveApplication(data)
    .then((response: any) => {
      if (response.success && props.fromApplicant) {
        formSubmitSuccess.value = true;
      } else if (response.success && !props.fromApplicant) {
        formSubmitSuccess.value = true;
        refresh();
      } else {
        node?.setErrors(t(`${response.message}`));
      }
    })
    .catch((error: any) => {
      node?.setErrors(t(`${error.message}`));
    });

  if (props.fromApplicant && data.jobAlerts) {
    data.recaptcha = await recaptcha();

    await client("/job-alert-subscription/submit", {
      method: "POST",
      body: data,
    }).then((response: any) => {
      if (!response.success) {
        node?.setErrors(t(`${response.message}`));
      }
    });
  }
};
</script>

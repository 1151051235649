import type {
  CreateApplicationAttributes,
  FetchApplicationAttributes,
  Application,
} from "~/types";

export const useApplication = () => {
  const strapiMethods = useStrapi();
  const strapiClient = useStrapiClient();
  const createApplication = async (
    data: Partial<CreateApplicationAttributes>,
  ) => {
    if (data.id) return;

    //return the promise, so we can await it in the component
    //create FormData to post
    const postData = new FormData();
    //append CV
    data.cv?.forEach((file: { name: string; file: File }) => {
      postData.append(`cv`, file.file, file.file.name);
    });
    //pictures are submitted as files, delete it from data
    delete data.cv;

    //append CV
    data.motivation?.forEach((file: { name: string; file: File }) => {
      postData.append(`motivation`, file.file, file.file.name);
    });
    //pictures are submitted as files, delete it from data
    delete data.motivation;

    //add to formdata
    postData.append("data", JSON.stringify(data));
    return await strapiClient("applications", {
      method: "POST",
      body: postData,
    });
  };
  const updateApplication = async (
    id: number,
    data: Partial<CreateApplicationAttributes>,
  ) => {
    return strapiMethods.update("applications", id, data).catch((err) => {
      console.log(err);
    });
  };

  const saveApplication = async (data: Partial<Application>) => {
    const { id, ...postFields } = data;
    if (id !== null && id !== undefined) {
      return updateApplication(id, postFields as CreateApplicationAttributes);
    } else {
      return createApplication(postFields as CreateApplicationAttributes);
    }
  };

  const fetchApplication = async (id: number) => {
    const response = await strapiMethods.findOne<FetchApplicationAttributes>(
      "applications",
      id,
      {
        populate: ["vacancy"],
      },
    );
    return response.data;
  };

  return { saveApplication, fetchApplication };
};
